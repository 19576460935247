.layer-popover {
  --width: auto;
  --max-width: 100%;
  //--height: 400px;
  .layer-row {
    //background-color: #eee;
    .layer-list {
      //--ion-color-base: transparent !important;
      .layer-list-item {
        //--ion-color-base: transparent !important;
        .layer-thumb {
          --border-radius: 10px;
        }
      }
    }
  }

}

.lng-popover {
  //--width: auto;
  .lng-list {
    .lng-list-item {
      .lng-thumb {
        --border-radius: 50%;
        img {
          position:relative;
          border: 1px solid #bbb;
          left:50%;
          top:50%;
          transform: translate(-50%, -50%);
          width: 80%;
          height: 80%;
        }
      }
    }
  }
}

.cesium-widget-credits {
  display:none;
}

#root-page {
  #main-header {
    .header-logo {
      --border-radius: 50%;
      img {
        position:relative;
        border: 1px solid #bbb;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 70%;
      }
    }
  }
  #tile-loader-progress-bar {
    --progress-background: #3b9c05;
    transition: opacity 1s ease-in;
    //--buffer-background: magenta;
    opacity: 0;
    &.visible {
      opacity:1;
    }
  }
  #main-content {
    --background: black;
    #resium-viewer {
      .cesium-viewer-timelineContainer, .cesium-viewer-animationContainer {
        //display:none;
        transition: transform 0.5s ease-in;
        transform:translateY(0%);

      }
      &.no-timeline {
        .cesium-viewer-timelineContainer, .cesium-viewer-animationContainer {
          transform:translateY(100%);
        }
      }
    }

    #globe-spinner {
      --color: white;
      text-align: center;
      position: absolute;
      left:50%;
      top:50%;
      transform: translate(-50%, -50%);
      div.message {
        color: rgba(white, 0.7);
      }
    }
  }
}
